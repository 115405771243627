import { createI18n } from 'vue-i18n';

import en from '@/i18n/locales/en.json';
import es from '@/i18n/locales/es.json';

const messages = {
  en,
  es,
};

export default createI18n({
  globalInjection: true,
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages,
});

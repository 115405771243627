import { computed } from 'vue';
import type { AxiosRequestConfig } from 'axios';
import { useTransformRequest } from '@/composable/request-utils/useTransformRequest';
import httpClient from '@/settings/HttpClient';
import { rootStore } from '@/store/rootStore';
import {
  ICompany,
  ICompanyContact,
  IStudioFilter,
  IStudioSettings,
} from '@/ts-definitions';

const companiesRequestOptions = (): Pick<AxiosRequestConfig, 'baseURL'> => ({
  baseURL: rootStore.state.sniffer.endpoints.COMPANIES_API_ENDPOINT,
});

const { removeCompanyToken } = useTransformRequest();

const companiesService = {
  listCompanies: async () =>
    httpClient
      .get<{ res: ICompany[] }>('/user/companies', {
        ...companiesRequestOptions(),
        params: {
          sort: 'name',
        },
        transformRequest: removeCompanyToken,
      })
      .then((data) => data.data),

  getCompany: async (key: string, slug: string) =>
    httpClient
      .get<ICompany>(`/company/${slug}`, companiesRequestOptions())
      .then((data) => data.data),

  patchCompany: async (slug: string, data: Partial<ICompany>) =>
    httpClient.patch(`/company/${slug}`, data, companiesRequestOptions()),

  putCompanyCurrencies: async (data: { currencies: string[] }) =>
    httpClient.put('/company/currency', data, companiesRequestOptions()),

  getCompanySettings: async () =>
    httpClient
      .get<{ settings: IStudioSettings; filters: IStudioFilter[] }>(
        '/company/settings',
        companiesRequestOptions()
      )
      .then((data) => data.data),

  patchCompanyStudioSettings: async (data: {
    filters: IStudioFilter[];
    settings: IStudioSettings;
  }) => {
    const filters = computed(() =>
      data.filters.map((filter) => ({
        company_id: filter.company_id,
        name: filter.name,
        value: filter.value,
        type: filter.type,
        sort: filter.sort,
      }))
    );

    return httpClient.patch(
      '/company/settings',
      {
        filters: filters.value,
        ...data.settings,
      },
      companiesRequestOptions()
    );
  },

  getCompanyContacts: async () =>
    httpClient
      .get<{ companies: ICompanyContact[] }>(
        `/company/contacts`,
        companiesRequestOptions()
      )
      .then((data) => data.data.companies),
};

export default companiesService;

import { RouteRecordRaw } from 'vue-router';
import i18n from '@/i18n';

import { routePaths } from '../routePaths';

const supplierDashboardRoutes: Array<RouteRecordRaw> = [
  {
    path: routePaths.Company.SupplierDashboard,
    name: 'SupplierDashboard',
    component: () =>
      import(
        /* webpackChunkName: "company" */ '@/layouts/Containers/SupplierDashboard.vue'
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '',
        redirect: { name: 'StudioContainer' }, //TO-DO Delete this line and uncomment the other code to enable company settingd and home
        // name: 'CompanyDashboard',
        // component: () =>
        //   import(
        //     /* webpackChunkName: "company-home" */ '@/views/Company/CompanyDashboard.vue'
        //   ),
        // meta: {
        //   title: i18n.global.t('routes.company_dashboard'),
        // },
      },
      {
        path: 'studio',
        name: 'StudioContainer',
        component: () =>
          import(
            /* webpackChunkName: "studio" */ '@/layouts/Containers/StudioContainer.vue'
          ),
        children: [
          {
            path: '',
            name: 'StudioDesigns',
            component: () =>
              import(
                /* webpackChunkName: "designs" */ '@/views/Company/StudioDesigns.vue'
              ),
            meta: {
              title: i18n.global.t('routes.studio_designs'),
            },
          },
          {
            path: 'templates',
            name: 'StudioTemplates',
            component: () =>
              import(
                /* webpackChunkName: "designs" */ '@/views/Company/StudioTemplates.vue'
              ),
            meta: {
              title: i18n.global.t('routes.STUDIO_TEMPLATES'),
              requiredRole: ['admin', 'manager', 'rep'],
            },
          },
          {
            path: 'settings',
            name: 'StudioSettings',
            component: () =>
              import(
                /* webpackChunkName: "settings" */ '@/views/Company/StudioSettings.vue'
              ),
            meta: {
              title: i18n.global.t('routes.studio_settings'),
              requiredRole: ['admin'],
            },
          },
        ],
      },
      {
        path: 'contacts',
        name: 'ContactsContainer',
        component: () =>
          import(
            /* webpackChunkName: "contacts" */ '@/layouts/Containers/ContactsContainer.vue'
          ),
        children: [
          {
            path: 'people',
            name: 'PeopleContainer',
            redirect: { name: 'PeopleList' },
            component: () =>
              import(
                /* webpackChunkName: "people" */ '@/layouts/Containers/PeopleContainer.vue'
              ),
            meta: {
              title: i18n.global.t('routes.people'),
            },
            children: [
              {
                path: '',
                name: 'PeopleList',
                component: () =>
                  import(
                    /* webpackChunkName: "people_list" */ '@/views/Company/PeopleList.vue'
                  ),
                meta: {
                  title: i18n.global.t('routes.people'),
                },
              },
              {
                path: ':id',
                name: 'PeopleProfile',
                component: () =>
                  import(
                    /* webpackChunkName: "people_profile" */ '@/views/Company/PeopleProfile.vue'
                  ),
                meta: {
                  title: i18n.global.t('routes.user_profile'),
                },
              },
            ],
          },
          {
            path: 'companies',
            alias: 'company',
            name: 'CompaniesContainer',
            meta: {
              title: i18n.global.t('routes.companies'),
            },
            component: () =>
              import(
                /* webpackChunkName: "contacts_companies" */ '@/layouts/Containers/CompaniesContainer.vue'
              ),
            children: [
              {
                path: '',
                name: 'CompaniesList',
                component: () =>
                  import(
                    /* webpackChunkName: "contacts_companies" */ '@/views/Contacts/CompaniesList.vue'
                  ),
                meta: {
                  title: i18n.global.t('routes.companies'),
                },
              },
              {
                path: '/company/:companyId/contacts/company/:contactId',
                name: 'ContactCompanyView',
                component: () =>
                  import(
                    /* webpackChunkName: "contacts_companies" */ '@/views/Contacts/ContactCompanyView.vue'
                  ),
                meta: {
                  title: i18n.global.t('routes.companies'),
                },
              },
            ],
          },
        ],
      },
      {
        path: 'settings',
        name: 'SettingsContainer',
        component: () =>
          import(
            /* webpackChunkName: "settings" */ '@/layouts/Containers/SettingsContainer.vue'
          ),
        meta: {
          requiredRole: ['admin', 'manager'],
        },
        children: [
          {
            path: '',
            name: 'PublicInformation',
            component: () =>
              import(
                /* webpackChunkName: "public_info" */ '@/views/Company/CompanyInformation.vue'
              ),
            meta: {
              title: i18n.global.t('routes.public_information'),
              requiredRole: ['admin'],
            },
          },
          {
            path: 'team-management',
            name: 'TeamManagement',
            component: () =>
              import(
                /* webpackChunkName: "team_management" */ '@/views/Company/TeamManagement.vue'
              ),
            meta: {
              title: i18n.global.t('routes.team_management'),
            },
          },
          {
            path: 'reports',
            name: 'Reports',
            component: () => import('@/views/Company/Reports.vue'),
            meta: {
              title: i18n.global.t('routes.reports'),
            },
          },
          {
            path: 'brand',
            name: 'BrandSettings',
            component: () =>
              import(
                /* webpackChunkName: "brand_settings" */ '@/views/Company/CompanyBrand.vue'
              ),
            meta: {
              title: i18n.global.t('routes.brand_settings'),
            },
          },
        ],
      },
      {
        path: 'asset',
        name: 'AssetsContainer',
        component: () =>
          import(
            /* webpackChunkName: "assets" */ '@/layouts/Containers/AssetsContainer.vue'
          ),
        children: [
          {
            path: 'library',
            name: 'AssetsLibrary',
            component: () =>
              import(
                /* webpackChunkName: "library" */ '@/views/Company/CompanyLibrary.vue'
              ),
            meta: {
              title: i18n.global.t('routes.library'),
            },
          },
        ],
      },
    ],
  },
];

export default supplierDashboardRoutes;

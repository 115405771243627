<template>
  <ZNotification :open="open" :color="color" @close="onClose">
    {{ message }}
  </ZNotification>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue';
import { ZNotification } from '@zoomcatalog/design-system';
import { useStore } from 'vuex';
import { RootStore, useCommit } from '@/store/rootStore';

export default defineComponent({
  name: 'GlobalNotification',
  components: {
    ZNotification,
  },
  setup() {
    const store = useStore<RootStore>();
    const commit = useCommit();

    return {
      ...toRefs(store.state.notification),
      onClose: () => commit({ type: 'notification/closeNotification' }),
    };
  },
});
</script>

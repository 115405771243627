<template>
  <div class="page-loader__container">
    <ZSpinner class="page-loader" size="lg" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZSpinner } from '@zoomcatalog/core-ui';

export default defineComponent({
  name: 'PageLoader',
  components: {
    ZSpinner,
  },
});
</script>

<style lang="scss" scoped>
.page-loader {
  &__container {
    @apply flex w-full h-full justify-center items-center py-4;
  }
}
</style>

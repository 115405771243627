import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import useRole from '@/composable/router/useRole';
import i18n from '@/i18n';
import CompanyInviteRoutes from '@/router/Modules/CompanyInviteRoutes';
import SupplierDashboardRoutes from '@/router/Modules/SupplierDashboardRoutes';
import { generatePageTitle } from '@/settings/PageTitle';
import { rootStore } from '@/store/rootStore';

import { routePaths } from './routePaths';

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean;
    title?: string;
    requiredRole?: string[];
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: routePaths.UserSettings,
    name: 'User',
    component: () =>
      import(/* webpackChunkName: "user" */ '@/views/UserSettings.vue'),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('routes.user_settings'),
    },
  },

  {
    path: routePaths.Company.CompanyHome,
    alias: routePaths.Home,
    name: 'CompanyHome',
    component: () =>
      import(
        /* webpackChunkName: "company" */ '@/views/Company/CompanyHome.vue'
      ),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('routes.company_home'),
    },
    children: SupplierDashboardRoutes,
  },

  {
    path: routePaths.Company.CreateCompany,
    name: 'CreateCompany',
    component: () =>
      import(
        /* webpackChunkName: "create-company" */ '@/views/Company/CreateCompany.vue'
      ),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('routes.create_company'),
    },
  },

  {
    path: routePaths.Company.NoCompany,
    name: 'NoCompany',
    component: () =>
      import(
        /* webpackChunkName: "no-company" */ '@/views/Company/NoCompany.vue'
      ),
    meta: {
      requiresAuth: true,
      title: i18n.global.t('routes.no_company'),
    },
  },

  {
    path: routePaths.SignIn,
    name: 'SignIn',
    component: () =>
      import(/* webpackChunkName: "sign-in" */ '@/views/SignIn.vue'),
    meta: {
      requiresAuth: false,
      title: i18n.global.t('routes.login'),
    },
  },

  {
    path: routePaths.SignUp,
    name: 'SignUp',
    component: () =>
      import(/* webpackChunkName: "sign-in" */ '@/views/SignUp.vue'),
    meta: {
      requiresAuth: false,
      title: i18n.global.t('routes.register'),
    },
  },

  {
    path: routePaths.ForgotPassword,
    name: 'ForgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ '@/views/ForgotPassword.vue'
      ),
    meta: {
      title: i18n.global.t('routes.forgot_password'),
    },
  },

  {
    path: routePaths.ResetPassword,
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '@/views/ResetPassword.vue'
      ),
    meta: {
      title: i18n.global.t('routes.reset_password'),
    },
  },

  {
    path: routePaths.ResetPasswordError,
    name: 'ResetPasswordError',
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ '@/views/ResetPasswordError.vue'
      ),
    meta: {
      title: i18n.global.t('routes.reset_password'),
    },
  },

  ...CompanyInviteRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let requiresAuth;
  if (!to.matched.every((record) => record.meta.requiresAuth === undefined)) {
    requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  }
  const requiredRole = to.matched.some((record) => record.meta.requiredRole);
  let { isAuthenticated } = rootStore.state.account;

  if (!isAuthenticated) {
    isAuthenticated = await rootStore.dispatch({
      type: 'account/checkIsUserAuthenticated',
    });
  }
  const { meta, params } = to;
  const { hasRouteAccess } = useRole();
  const hasRequiredRole = hasRouteAccess(meta, params);
  document.title = generatePageTitle(meta, params);

  return !isAuthenticated && requiresAuth
    ? next({ path: routePaths.SignIn })
    : isAuthenticated && requiresAuth === false
    ? next({ path: routePaths.Home })
    : requiredRole && !hasRequiredRole
    ? next({ path: routePaths.Home })
    : next();
});

export default router;

import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import router from '@/router';
import { settings } from '../../settings/AppSettings';

const sentrySettings = {
  environment: import.meta.env.MODE as string,
  dsn: settings.SENTRY_DSN as string,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', settings.CRM_UI_URL, /^\//],
    }),
  ],
  debug: false,
  // tracesSampleRate: import.meta.env.MODE === 'production' ? 0.2 : 1,
  tracingOptions: {
    trackComponents: true,
  },
  // Vue specific
  logErrors: import.meta.env.MODE !== 'production',
  attachProps: true,
  attachStacktrace: true,
};

export default sentrySettings;

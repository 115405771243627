import { AxiosTransformer } from 'axios';

export function useTransformRequest() {
  const removeCompanyToken: AxiosTransformer = (data, headers) => {
    if (headers.common['X-Zoom-Company']) {
      delete headers.common['X-Zoom-Company'];
    }
    return data;
  };

  return {
    removeCompanyToken,
  };
}

<template>
  <div id="app">
    <ZGlobalNotification />

    <router-view :key="companyId" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useRoute } from 'vue-router';
import { ZGlobalNotification } from '@/components/feedback';

export default defineComponent({
  name: 'App',
  components: {
    ZGlobalNotification,
  },
  setup() {
    const route = useRoute();

    const companyId = computed(() => route.params.companyId?.toString());
    return {
      companyId,
    };
  },
});
</script>

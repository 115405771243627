import { RouteRecordRaw } from 'vue-router';
import useMiddleware from '@/composable/router/useMiddleware';
import i18n from '@/i18n';

import { routePaths } from '../routePaths';

const companyInviteRoutes: Array<RouteRecordRaw> = [
  {
    path: routePaths.CompanyInvite.InviteAccept,
    name: 'InviteAccept',
    component: () =>
      import(
        /* webpackChunkName: "invite-accept" */ '@/views/InviteAccept.vue'
      ),
    meta: {
      title: i18n.global.t('routes.invite_accept'),
    },
  },

  {
    path: routePaths.CompanyInvite.InviteSignIn,
    name: 'InviteSignIn',
    component: () =>
      import(
        /* webpackChunkName: "invite-accept" */ '@/views/InviteSignIn.vue'
      ),
    beforeEnter: (to) => {
      const middleware = useMiddleware();
      return middleware.inviteMiddleware(to, ['t']);
    },
    meta: {
      title: i18n.global.t('routes.invite_sign_in'),
    },
  },

  {
    path: routePaths.CompanyInvite.InviteSignUp,
    name: 'InviteSignUp',
    component: () =>
      import(
        /* webpackChunkName: "invite-accept" */ '@/views/InviteSignUp.vue'
      ),
    beforeEnter: (to) => {
      const middleware = useMiddleware();
      return middleware.inviteMiddleware(to, ['t', 'email']);
    },
    meta: {
      title: i18n.global.t('routes.invite_sign_up'),
    },
  },

  {
    path: routePaths.CompanyInvite.InviteAcceptError,
    name: 'InviteError',
    component: () =>
      import(/* webpackChunkName: "invite-accept" */ '@/views/InviteError.vue'),
    meta: {
      title: i18n.global.t('routes.invite_error'),
    },
  },
];

export default companyInviteRoutes;

import { Module } from 'vuex';

import type { Event, EventPayload } from '@/ts-definitions';
import type { RootStore } from '../rootStore';

export interface NotificationStore {
  open: boolean;
  message?: string;
  color: 'primary' | 'success' | 'warning' | 'danger';
  anchor: {
    vertical: 'top' | 'center' | 'bottom';
    horizontal: 'left' | 'center' | 'right';
  };
}

export type NotificationMutations = EnqueueNotification | CloseNotification;

export type EnqueueNotification = EventPayload<
  'notification/enqueueNotification',
  { message: string; color?: NotificationStore['color'] }
>;

type CloseNotification = Event<'notification/closeNotification'>;

export const notificationStore: Module<NotificationStore, RootStore> = {
  namespaced: true,
  state: () => ({
    open: false,
    message: undefined,
    color: 'success',
    anchor: {
      vertical: 'top',
      horizontal: 'center',
    },
  }),
  mutations: {
    enqueueNotification: (state, { payload }: EnqueueNotification) => {
      state.message = payload.message;
      state.color = payload.color ?? 'success';
      state.open = true;
    },
    closeNotification: (state) => {
      state.open = false;
      state.message = undefined;
    },
  },
};

<template>
  <div class="prompt">
    <div class="prompt__message">
      <p class="prompt__message--title">
        {{ $t('studio.PROMPT_TITLE') }}
      </p>
      <p class="prompt__message--text">
        {{ $t('studio.PROMPT_TEXT') }}
      </p>
    </div>
    <ZButton color="primary" :href="settings.TEMPLATE_TUTORIAL" target="_blank">
      {{ $t('actions.WATCH_TUTORIAL') }}
    </ZButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ZButton } from '@zoomcatalog/core-ui';
import { settings } from '@/settings/AppSettings';

export default defineComponent({
  name: 'PromptMessage',
  components: {
    ZButton,
  },
  setup() {
    return { settings };
  },
});
</script>

<style lang="scss" scoped>
.prompt {
  @apply flex items-center justify-between bg-brand-darkblue py-6 px-10 rounded-md;
  &__message {
    @apply flex flex-col text-white;
    &--title {
      @apply text-longform-lg font-medium leading-7 mb-1;
    }
    &--text {
      @apply text-longform-xs leading-5;
    }
  }
}
</style>

import '@zoomcatalog/design-system/dist/main.css';
import '@zoomcatalog/core-ui/dist/main.css';
import '@/assets/styles/index.scss';

import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { useSnifferServices } from '@zoomcatalog/composable';
import App from '@/App.vue';
import i18n from '@/i18n';
import router from '@/router';
import { rootStore, useCommit } from '@/store/rootStore';
import sentrySettings from './config/sentry';
import { SNIFFER_SERVICES } from './settings/AppServices';
import { settings, ENV } from './settings/AppSettings';
import httpClient from './settings/HttpClient';

async function prepare() {
  if (settings.ENABLE_MSW) {
    const { worker } = await import('./mocks/browser');
    return worker.start({ onUnhandledRequest: 'bypass' });
  } else {
    await setupSniffer();
  }
}

prepare().then(() => {
  const app = createApp(App);

  if (!['development', 'testing'].includes(import.meta.env.MODE)) {
    Sentry.init({ app, ...sentrySettings });
  }

  app.use(i18n).use(rootStore).use(router).use(VueQueryPlugin).mount('#root');

  if (settings.USE_ANALYTICS) {
    window.analytics.load(settings.SEGMENT_KEY);
  }
});

async function setupSniffer() {
  const commit = useCommit();

  const enviroment = import.meta.env.MODE as ENV;
  if (enviroment !== 'development') {
    const snifferService = await useSnifferServices({
      httpGetRequest: httpClient.get,
      snifferQuery: {
        enviroment: enviroment === 'production' ? 'prod' : enviroment,
        servicesList: [...SNIFFER_SERVICES],
      },
    });
    commit({
      type: 'sniffer/setServicesEndpoints',
      payload: snifferService.baseUrls,
    });
  }
}

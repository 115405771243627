export type FilterSchema = string | null;
export type JoinKey = '=' | ':eq:';

const filterSchema = (
  filters: Record<string, unknown>,
  joinKey: JoinKey
): FilterSchema => {
  return (
    Object.keys(filters)
      .filter((key) => typeof filters[key] !== 'undefined')
      .map((key) => `${key}${joinKey}${filters[key]}`)
      .join(',') || null
  );
};
export const getDefaultFilterSchema = (
  filtersObject: Record<string, unknown>
): FilterSchema => {
  return filterSchema(filtersObject, '=');
};

export const getEQFilterSchema = (
  filtersObject: Record<string, unknown>
): FilterSchema => {
  return filterSchema(filtersObject, ':eq:');
};

import type { AxiosRequestConfig } from 'axios';
import { routePaths } from '@/router/routePaths';
import { settings } from '@/settings/AppSettings';
import httpClient from '@/settings/HttpClient';
import { rootStore } from '@/store/rootStore';

const authRequestOptions = (): Pick<AxiosRequestConfig, 'baseURL'> => ({
  baseURL: rootStore.state.sniffer.endpoints.AUTH_API_ENDPOINT,
});
const usersRequestOptions = (): Pick<AxiosRequestConfig, 'baseURL'> => ({
  baseURL: rootStore.state.sniffer.endpoints.USERS_API_ENDPOINT,
});

const accountService = {
  signIn: async (data: { email: string; password: string }) =>
    httpClient
      .post<{ user_token: string; refresh_token: string }>(
        '/auth/authorize',
        {
          username: data.email,
          password: data.password,
          grant_type: 'password',
          client_id: settings.CRM_CLIENT_ID,
        },
        authRequestOptions()
      )
      .then((data) => data.data),

  signUp: async (data: {
    password: string;
    firstname: string;
    lastname: string;
    token: string;
  }) =>
    httpClient
      .post<{ uid: string }>(
        '/user/signup/invitation',
        {
          password: data.password,
          firstname: data.firstname,
          lastname: data.lastname,
        },
        {
          ...usersRequestOptions(),
          headers: { Authorization: data.token },
        }
      )
      .then((data) => data.data),

  refreshToken: async (data: { refreshToken: string }) =>
    httpClient
      .post<{ user_token: string; refresh_token: string }>(
        '/auth/authorize',
        {
          refresh_token: data.refreshToken,
          grant_type: 'refresh_token',
          client_id: settings.CRM_CLIENT_ID,
        },
        authRequestOptions()
      )
      .then((data) => data.data),

  forgotPassword: async (data: { email: string }) =>
    httpClient.post(
      '/user/recover-password',
      {
        username: data.email,
        callback: `${settings.CRM_UI_URL}${routePaths.ResetPassword}`,
        callback_error: `${settings.CRM_UI_URL}${routePaths.ResetPasswordError}`,
      },
      usersRequestOptions()
    ),

  resetPassword: async (data: { password: string; token: string }) =>
    httpClient.put(
      '/user/password',
      { password: data.password },
      {
        ...authRequestOptions(),
        headers: { Authorization: data.token },
      }
    ),

  validateToken: async (data: { token: string }) =>
    httpClient
      .get('/team-members/validate-token', {
        params: {
          token: data.token,
        },
      })
      .then((data) => data.data),

  signInUrl: async (data: { url: string }) =>
    httpClient
      .post<{ url: string }>(
        '/auth/authorize/url',
        {
          url: data.url,
          client_id: settings.CRM_CLIENT_ID,
        },
        authRequestOptions()
      )
      .then((data) => data.data),
};

export default accountService;

import type { AxiosRequestConfig } from 'axios';
import router from '@/router';
import httpClient from '@/settings/HttpClient';
import { rootStore } from '@/store/rootStore';
import { IUser, IUserContact, IUserContactInformation } from '@/ts-definitions';
import { getEQFilterSchema } from '@/utils/filterUtils';

export type IUserContactCreatePayload = {
  avatar: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  job_title: string;
  typ: string;
  company_association: string | null;
  display_email: string;
  display_logo: string;
  display_phone: string;
};

export type IUserContactUpdatePayload = Omit<
  IUserContactCreatePayload,
  'email'
>;

const authRequestOptions = (): Pick<AxiosRequestConfig, 'baseURL'> => ({
  baseURL: rootStore.state.sniffer.endpoints.AUTH_API_ENDPOINT,
});
const usersRequestOptions = (): Pick<AxiosRequestConfig, 'baseURL'> => ({
  baseURL: rootStore.state.sniffer.endpoints.USERS_API_ENDPOINT,
});
const companiesRequestOptions = (): Pick<AxiosRequestConfig, 'baseURL'> => ({
  baseURL: rootStore.state.sniffer.endpoints.COMPANIES_API_ENDPOINT,
});

const usersService = {
  getUser: async () =>
    httpClient
      .get<IUser>(`/user`, usersRequestOptions())
      .then((data) => data.data),

  patchUser: async (data: {
    email?: string;
    firstname: string;
    lastname: string;
  }) =>
    httpClient
      .patch(`/user`, data, usersRequestOptions())
      .then((data) => data.data),

  updatePassword: async (data: {
    currentPassword: string;
    newPassword: string;
  }) =>
    httpClient.put(
      `/user/password`,
      {
        old_password: data.currentPassword,
        password: data.newPassword,
      },
      authRequestOptions()
    ),

  getUserContacts: async (
    key: string,
    data: { limit: number },
    offset?: number
  ) => {
    const route = router.currentRoute;
    const { q, is_subscribed, company_association_slug } = route.value.query;
    const filters = getEQFilterSchema({
      is_subscribed,
      company_association_slug,
    });
    return httpClient
      .get<{ res: IUserContact[] }>('/users/company/contacts', {
        params: {
          limit: data.limit,
          offset,
          q,
          filters,
        },
        ...companiesRequestOptions(),
      })
      .then((data) => data.data.res);
  },

  createUserContact: async (data: { email: string; avatar: string }) =>
    httpClient
      .post<{ uid: string }>(`/user/contact`, data, usersRequestOptions())
      .then((data) => data.data),

  editUserContact: async (data: IUserContactUpdatePayload, email: string) =>
    httpClient
      .patch(`/user/contact/${email}`, data, usersRequestOptions())
      .then((data) => data.data),

  getUserContact: async (key: string, uid: string) =>
    httpClient
      .get<IUserContactInformation>(
        `user/contact/${uid}`,
        usersRequestOptions()
      )
      .then((data) => data.data),

  changeContactActiveStatus: async (
    data: { is_active: boolean },
    uid: string
  ) =>
    httpClient
      .put(`user/contact/${uid}/active`, data, usersRequestOptions())
      .then((data) => data.data),

  changeContactSubscribedStatus: async (
    data: { is_subscribed: boolean },
    uid: string
  ) =>
    httpClient
      .put(`user/contact/${uid}/subscribed`, data, usersRequestOptions())
      .then((data) => data.data),

  deleteContact: async (uid: string) =>
    httpClient
      .delete(`user/contact/${uid}`, usersRequestOptions())
      .then((data) => data.data),
};

export default usersService;

<template>
  <div class="zoom-badge">
    <div class="zoom-badge--powered">{{ $t('strings.powered_by') }}</div>
    <a class="zoom-badge--logo" :href="zoomUrl" target="_blank">
      <ZCBadge />
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ZCBadge from '@/assets/icons/zc-badge-icon.svg';
import { settings } from '@/settings/AppSettings';

export default defineComponent({
  name: 'ZoomBadge',
  components: {
    ZCBadge,
  },
  setup() {
    const zoomUrl = settings.ZOOMCATALOG_URL;

    return {
      zoomUrl,
    };
  },
});
</script>

<style lang="scss" scoped>
.zoom-badge {
  @apply flex justify-center items-center;
  &--powered {
    @apply text-ui-xs text-secondary mx-3;
  }
}
</style>

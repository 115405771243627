<template>
  <div
    :class="['company-icon__container', `company-icon--${size}`]"
    :style="{ backgroundColor: bgColor || '#1670f6' }"
  >
    <span v-if="!$slots.customImage" class="company-icon__letter">
      <slot />
    </span>
    <slot name="customImage" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CompanyIcon',
  props: {
    size: {
      type: String,
      default: 'sm',
      validator: (value: string) => ['sm', 'md'].includes(value),
    },
    bgColor: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped>
.company-icon {
  &__container {
    @apply w-6 h-6 rounded-md flex justify-center items-center overflow-hidden;
  }
  &--sm {
    @apply w-6 h-6 text-ui-xs;
  }
  &--md {
    @apply w-10 h-10 text-longform-lg;
  }
  &__letter {
    @apply font-medium opacity-50 text-white;
  }
}
</style>

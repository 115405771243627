import { RouteMeta, RouteParams, useRouter, useRoute } from 'vue-router';
import { rootStore } from '@/store/rootStore';

export default function useRole() {
  const hasRoleAccess = (meta: RouteMeta, params: RouteParams) => {
    const { companies } = rootStore.state.account;
    if (meta.requiredRole) {
      const requiredCompany = companies?.find(
        (company) => company.slug === params.companyId
      );
      if (requiredCompany?.role_name) {
        return meta.requiredRole.includes(requiredCompany.role_name);
      }
    }
    return true;
  };
  const hasRouteAccess = (meta: RouteMeta, params: RouteParams) =>
    hasRoleAccess(meta, params);

  const hasLinkAccess = (targetRouteName: string) => {
    const router = useRouter();
    const { params } = useRoute();
    const { meta } = router.resolve({ name: targetRouteName });
    return hasRoleAccess(meta, params);
  };

  const hasFeatureAccess = (requiredRole: string[] | undefined) => {
    if (!requiredRole) return true;
    const { companies } = rootStore.state.account;
    const { params } = useRoute();
    const requiredCompany = companies?.find(
      (company) => company.slug === params.companyId
    );
    if (requiredCompany?.role_name) {
      return requiredRole.includes(requiredCompany.role_name);
    }
    return false;
  };

  return {
    hasRouteAccess,
    hasLinkAccess,
    hasFeatureAccess,
  };
}

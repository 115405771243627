export const routePaths = {
  Home: '/',
  UserSettings: '/user/settings',
  Company: {
    CompanyHome: '/company',
    SupplierDashboard: ':companyId',
    Studio: 'studio',
    StudioSettings: 'studio/settings',
    Settings: 'settings',
    TeamManagement: 'team-management',
    Reports: 'reports',
    CreateCompany: '/company/create',
    NoCompany: '/company/no-company',
  },
  SignIn: '/login',
  SignUp: '/login/sign-up',
  ForgotPassword: '/login/forgot',
  ResetPassword: '/login/new-password',
  ResetPasswordError: '/login/new-password/error',
  CompanyInvite: {
    InviteAccept: '/invite',
    InviteAcceptError: '/invite/error',
    InviteSignIn: '/invite/sign-in',
    InviteSignUp: '/invite/sign-up',
  },
} as const;

interface Settings {
  CRM_UI_URL: string;
  CRM_CLIENT_ID: string;
  QC_UI_URL: (slug: string) => string;
  QC_TEMPLATE_UI_URL: (slug: string) => string;
  QC_VIEWER_URL: (shortId: string, slug: string, companySlug: string) => string;
  ENABLE_MSW: boolean; // Enable/Disable Mock Service Worker
  SEGMENT_KEY: string;
  SENTRY_DSN: string;
  USE_ANALYTICS: boolean;
  ZOOMCATALOG_URL: string;
  TEMPLATE_TUTORIAL: string;
  UNSPLASH_KEY: string;
  PLACEHOLDER_THUMBNAIL_URL: string;
  ASSET_COLLECTION: {
    IMAGE_TYPE: number;
    IMAGE_CATEGORY: number;
  };
  SLUGS: {
    MOOZ: string;
  };
  ZOOM_REFERER_URL: (slug: string) => string;
}

export type ENV = 'development' | 'alpha' | 'beta' | 'production';

const AMROD_QC_VIEWER_URL = `https://studio.promoafrica.com`;
const AMROD_QC_UI_URL = `https://create.amrodstudio.co.za`;
const AMROD_SLUG = 'amrod';

export const sharedKeys = {
  ZOOMCATALOG_URL: 'https://www.zoomcatalog.com',
  SEGMENT_KEY: 'fw8i7o9MOD52AecEJhc9XBH31pcRuc0I',
  SENTRY_DSN:
    'https://47e5cb92552e4435bb0c5c5f4d5cdea7@o890439.ingest.sentry.io/5899277',
  TEMPLATE_TUTORIAL: 'https://www.youtube.com/watch?v=V0LEfloVkkc',
  PLACEHOLDER_THUMBNAIL_URL:
    'https://501438041880-zoomcatalog-public.s3.amazonaws.com/assets/placeholder.svg',
  SLUGS: {
    MOOZ: 'mooz',
  },
};

export const VUE_APP: Record<ENV, Settings> = {
  development: {
    CRM_CLIENT_ID: 'zoomcatalog.crm.alpha',
    CRM_UI_URL: 'https://alpha-supplier.zoomcatalog.com',
    QC_UI_URL: (slug: string) =>
      `https://alpha-${slug}-quickcanvas.zoomcatalog.com`,
    QC_TEMPLATE_UI_URL: (slug: string) =>
      `https://alpha-${slug}-quickcanvas.zoomcatalog.com?type=template`,
    QC_VIEWER_URL: (shortId, slug) =>
      `https://alpha-canvas.zoomcats.com/${shortId}/${slug}`,
    ENABLE_MSW: true,
    USE_ANALYTICS: false,
    UNSPLASH_KEY: 'XCsOXZdJGGANt5UkjOCBTQqNV-1twDRkEQ_EszdHuEI',
    ASSET_COLLECTION: {
      IMAGE_TYPE: 4,
      IMAGE_CATEGORY: 5,
    },
    ZOOM_REFERER_URL: (slug: string) =>
      `https://alpha-${slug}-quickcanvas.zoomcatalog.com`,
    ...sharedKeys,
  },

  alpha: {
    CRM_CLIENT_ID: 'zoomcatalog.crm.alpha',
    CRM_UI_URL: 'https://alpha-supplier.zoomcatalog.com',
    QC_UI_URL: (slug: string) =>
      `https://alpha-${slug}-quickcanvas.zoomcatalog.com`,
    QC_TEMPLATE_UI_URL: (slug: string) =>
      `https://alpha-${slug}-quickcanvas.zoomcatalog.com?type=template`,
    QC_VIEWER_URL: (shortId, slug) =>
      `https://alpha-canvas.zoomcats.com/${shortId}/${slug}`,
    ENABLE_MSW: false,
    USE_ANALYTICS: false,
    UNSPLASH_KEY: 'XCsOXZdJGGANt5UkjOCBTQqNV-1twDRkEQ_EszdHuEI',
    ASSET_COLLECTION: {
      IMAGE_TYPE: 4,
      IMAGE_CATEGORY: 5,
    },
    ZOOM_REFERER_URL: (slug: string) =>
      `https://alpha-${slug}-quickcanvas.zoomcatalog.com`,
    ...sharedKeys,
  },

  beta: {
    CRM_CLIENT_ID: 'zoomcatalog.crm.beta',
    CRM_UI_URL: 'https://beta-supplier.zoomcatalog.com',
    QC_UI_URL: (slug: string) =>
      `https://beta-${slug}-quickcanvas.zoomcatalog.com`,
    QC_TEMPLATE_UI_URL: (slug: string) =>
      `https://beta-${slug}-quickcanvas.zoomcatalog.com?type=template`,
    QC_VIEWER_URL: (shortId, slug) =>
      `https://beta-canvas.zoomcats.com/${shortId}/${slug}`,
    ENABLE_MSW: false,
    USE_ANALYTICS: false,
    UNSPLASH_KEY: 'eJ_f-XNXwSM9BKVlbjv223eNxxgFQSheQupXyqHqCqE',
    ASSET_COLLECTION: {
      IMAGE_TYPE: 5,
      IMAGE_CATEGORY: 6,
    },
    ZOOM_REFERER_URL: (slug: string) =>
      `https://beta-${slug}-quickcanvas.zoomcatalog.com`,
    ...sharedKeys,
  },

  production: {
    CRM_CLIENT_ID: 'zoomcatalog.crm.prod',
    CRM_UI_URL: 'https://supplier.zoomcatalog.com',
    QC_UI_URL: (slug: string) => {
      if (slug === AMROD_SLUG) return AMROD_QC_UI_URL;

      return `https://${slug}-quickcanvas.zoomcatalog.com`;
    },
    QC_TEMPLATE_UI_URL: (slug: string) => {
      if (slug === AMROD_SLUG) return AMROD_QC_UI_URL;

      return `https://${slug}-quickcanvas.zoomcatalog.com?type=template`;
    },
    QC_VIEWER_URL: (shortId, slug, companySlug) => {
      if (companySlug === AMROD_SLUG)
        return `${AMROD_QC_VIEWER_URL}/${shortId}/${slug}`;

      return `https://canvas.zoomcats.com/${shortId}/${slug}`;
    },
    ENABLE_MSW: false,
    USE_ANALYTICS: true,
    UNSPLASH_KEY: 'eFD1W4-8dIMdhaw-g0Sc4dm3lVZ7wvJIk238zfer9WM',
    ASSET_COLLECTION: {
      IMAGE_TYPE: 1,
      IMAGE_CATEGORY: 2,
    },
    ZOOM_REFERER_URL: (slug: string) =>
      `https://${slug}-quickcanvas.zoomcatalog.com`,
    ...sharedKeys,
  },
};

export const settings: Settings =
  VUE_APP[import.meta.env.MODE as ENV] ?? VUE_APP.development;

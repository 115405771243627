import axios from 'axios';

type ENV = 'development' | 'alpha' | 'beta' | 'production';
export const SNIFFER_ENDPOINT = 'https://sniffer.zoomcatalog.com/sniffer';
export const ENVIRONMENT = import.meta.env.MODE as ENV;
export const SNIFFER_SERVICES = [
  'auth',
  'companies',
  'assets',
  'helpers',
  'quickcanvas',
  'users',
  'invitations',
  'product-library',
] as const;
type Endpoint =
  | 'AUTH_API_ENDPOINT'
  | 'QC_API_ENDPOINT'
  | 'COMPANIES_API_ENDPOINT'
  | 'USERS_API_ENDPOINT'
  | 'HELPERS_API_ENDPOINT'
  | 'ASSET_SERVICE_API_ENDPOINT'
  | 'INVITATIONS_API_ENDPOINT'
  | 'PRODUCT_LIBRARY_API_ENDPOINT';

const unsnifferedEndpoints: Record<ENV, Record<string, never>> = {
  development: {},
  alpha: {},
  beta: {},
  production: {},
};

const state = <Record<Endpoint, string>>{};

export async function fetchSnifferEndpoints() {
  const response = await axios.get('/', {
    baseURL: SNIFFER_ENDPOINT,
    params: {
      service: SNIFFER_SERVICES.join(','),
      environment: ENVIRONMENT === 'production' ? 'prod' : ENVIRONMENT,
    },
  });
  setEndpoints(response.data);
  setUnsniffedEndpoints();
}

function setUnsniffedEndpoints() {
  Object.assign(state, {
    ...unsnifferedEndpoints[ENVIRONMENT],
  });
}

function setEndpoints(
  services: Record<typeof SNIFFER_SERVICES[number], string>
) {
  Object.assign(state, {
    AUTH_API_ENDPOINT: services.auth,
    QC_API_ENDPOINT: services.quickcanvas,
    COMPANIES_API_ENDPOINT: services.companies,
    USERS_API_ENDPOINT: services.users,
    HELPERS_API_ENDPOINT: services.helpers,
    ASSET_SERVICE_API_ENDPOINT: services.assets,
    INVITATIONS_API_ENDPOINT: services.invitations,
    PRODUCT_LIBRARY_API_ENDPOINT: services['product-library'],
  });
}

export function getEndpoints() {
  return state;
}

export function getEndpoint(service: Endpoint) {
  return state[service];
}

<template>
  <div v-if="loading" class="loader--message">
    <LoadingIcon class="animate-spin" />
    <span>
      {{ $t('strings.loading_more') }}
    </span>
  </div>
  <div v-else-if="!loading && hasNoResults" class="loader--message">
    <span>
      {{ $t('strings.no_more_results') }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoadingIcon from '@/assets/icons/loading-icon-gray.svg';

export default defineComponent({
  name: 'LoadingMore',
  components: {
    LoadingIcon,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    hasNoResults: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.loader--message {
  @apply text-ui-sm font-medium text-secondary flex items-center justify-center p-6;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.animate-spin {
  -webkit-transform-origin: center center;
  -webkit-animation: spin 1s infinite linear;
  @apply mr-2;
}
</style>

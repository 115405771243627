import { SnifferUrls } from '@zoomcatalog/design-system';
import { Module } from 'vuex';
import { EventPayload } from '@/ts-definitions';
import { RootStore } from '../rootStore';

export interface SnifferStore {
  endpoints: Partial<SnifferUrls>;
}

type SnifferNamespace = 'sniffer';

type SetServicesEndpointsMutation = EventPayload<
  `${SnifferNamespace}/setServicesEndpoints`,
  Partial<SnifferUrls>
>;

export type SnifferMutations = SetServicesEndpointsMutation;

export const snifferStore: Module<SnifferStore, RootStore> = {
  namespaced: true,
  state: {
    endpoints: {},
  },
  mutations: {
    setServicesEndpoints(state, { payload }: SetServicesEndpointsMutation) {
      state.endpoints = payload;
    },
  },
};

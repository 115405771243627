import { RouteMeta, RouteParams } from 'vue-router';

export const companyTitle: Record<string, unknown> = {
  gemline: 'Gemline',
  zoom_catalog: 'ZOOMcatalog',
};

export const generatePageTitle = (meta: RouteMeta, params: RouteParams) => {
  const pageTitle = [];
  if (meta.title) {
    pageTitle.push(meta.title);
  }
  if (params.companyId) {
    const companyId = params.companyId.toString();
    if (companyTitle[companyId]) {
      pageTitle.push(companyTitle[companyId]);
    }
  }
  pageTitle.push(companyTitle['zoom_catalog']);
  return pageTitle.join(' - ');
};

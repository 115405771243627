import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  AxiosRequestConfig,
} from 'axios';
import accountService from '@/services/account-service';
import { SignInMutation } from '@/store/modules/account';
import { rootStore } from '@/store/rootStore';

export type TData = Record<string, any>;

export interface HttpResponse<T extends TData = TData> {
  data: T;
  status: number;
  headers: Record<string, string>;
  request: AxiosResponse['request'];
}

interface ErrorResponse {
  message: string;
  code: number;
}

interface RequestOptions {
  baseURL?: AxiosRequestConfig['baseURL'];
  params?: AxiosRequestConfig['params'];
  headers?: AxiosRequestConfig['headers'];
  transformRequest?: AxiosRequestConfig['transformRequest'];
}

export const requestRetriesLimit = 3;

class HttpClient {
  #client: AxiosInstance;

  #refreshTokenRetries = 0;

  #refreshTokenInterceptor: number | undefined;

  #REFRESH_TOKEN_CODE = 401 as const;

  constructor() {
    this.#client = axios.create();
  }

  private getAxiosResponse<T extends TData = TData>(
    response: AxiosResponse<T>
  ): HttpResponse<T> {
    return {
      data: response.data,
      status: response.status,
      headers: response.headers,
      request: response.request,
    };
  }

  private getAxiosError(error: AxiosError): ErrorResponse {
    return {
      message: error.response?.data.message,
      code: error.response?.data.code,
    };
  }

  clearConfig() {
    this.setAuthorizationHeader();
    this.setCompanyHeader();
    this.removeRefreshTokenInterceptor();
  }

  setAuthorizationHeader(token: string | null = null) {
    delete this.#client.defaults.headers.common.Authorization;
    if (token) {
      this.#client.defaults.headers.common.Authorization = token;
    }
  }

  setCompanyHeader(companyId: string | null = null) {
    delete this.#client.defaults.headers.common['X-Zoom-Company'];
    if (companyId) {
      this.#client.defaults.headers.common['X-Zoom-Company'] = companyId;
    }
  }

  setRefreshTokenInterceptor() {
    this.removeRefreshTokenInterceptor();
    this.#refreshTokenInterceptor = this.#client.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        const originalRequest = error.config;

        if (
          error.response?.status === this.#REFRESH_TOKEN_CODE &&
          this.#refreshTokenRetries === 0
        ) {
          this.#refreshTokenRetries += 1;
          await this.refreshAuthToken();
          this.#refreshTokenRetries = 0;
          delete originalRequest.headers.Authorization;
          return this.#client(originalRequest);
        }
        return Promise.reject(error);
      }
    );
  }

  removeRefreshTokenInterceptor() {
    this.#refreshTokenInterceptor &&
      this.#client.interceptors.response.eject(this.#refreshTokenInterceptor);
  }

  async refreshAuthToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken === null) {
      return Promise.reject(new Error('Refresh token not found.'));
    }

    const tokens = await accountService.refreshToken({
      refreshToken,
    });

    const event: SignInMutation = {
      type: 'account/signIn',
      payload: {
        userToken: tokens.user_token,
        refreshToken: tokens.refresh_token,
      },
    };
    rootStore.commit(event);
  }

  async get<T extends TData = TData>(
    url: string,
    options?: RequestOptions
  ): Promise<HttpResponse<T>> {
    try {
      const res = await this.#client.get<T>(url, options);
      return this.getAxiosResponse<T>(res);
    } catch (error) {
      throw this.getAxiosError(error);
    }
  }

  async head<T extends TData = TData>(
    url: string,
    options?: RequestOptions
  ): Promise<HttpResponse<T>> {
    const res = await this.#client.head<T>(url, options);
    return this.getAxiosResponse<T>(res);
  }

  async options<T extends TData = TData>(
    url: string,
    options?: RequestOptions
  ): Promise<HttpResponse<T>> {
    const res = await this.#client.options<T>(url, options);
    return this.getAxiosResponse<T>(res);
  }

  async post<T extends TData = TData>(
    url: string,
    data?: unknown,
    options?: RequestOptions
  ): Promise<HttpResponse<T>> {
    try {
      const res = await this.#client.post<T>(url, data, options);
      return this.getAxiosResponse<T>(res);
    } catch (error) {
      throw this.getAxiosError(error);
    }
  }

  async put<T extends TData = TData>(
    url: string,
    data?: unknown,
    options?: RequestOptions
  ): Promise<HttpResponse<T>> {
    try {
      const res = await this.#client.put<T>(url, data, options);
      return this.getAxiosResponse<T>(res);
    } catch (error) {
      throw this.getAxiosError(error);
    }
  }

  async patch<T extends TData = TData>(
    url: string,
    data?: unknown,
    options?: RequestOptions
  ): Promise<HttpResponse<T>> {
    try {
      const res = await this.#client.patch<T>(url, data, options);
      return this.getAxiosResponse<T>(res);
    } catch (error) {
      throw this.getAxiosError(error);
    }
  }

  async delete<T extends TData = TData>(
    url: string,
    options?: RequestOptions
  ): Promise<HttpResponse<T>> {
    try {
      const res = await this.#client.delete<T>(url, options);
      return this.getAxiosResponse<T>(res);
    } catch (error) {
      throw this.getAxiosError(error);
    }
  }
}

const httpClient = new HttpClient();
export default httpClient;

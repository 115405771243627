import type { EnqueueNotification } from '@/store/modules/notification';
import { useCommit } from '@/store/rootStore';

export function useNotification() {
  const commit = useCommit();

  function enqueueNotification(payload: EnqueueNotification['payload']) {
    commit({
      type: 'notification/enqueueNotification',
      payload,
    });
  }

  function closeNotification() {
    commit({
      type: 'notification/closeNotification',
    });
  }

  return {
    enqueueNotification,
    closeNotification,
  };
}

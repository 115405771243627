import { useQueryCache } from '@zoomcatalog/query-client';
import { RouteLocation, RouteLocationRaw, useRoute } from 'vue-router';
import { Module } from 'vuex';
import useAnalytics from '@/composable/useAnalytics';
import { useNotification } from '@/composable/useNotification';
import router from '@/router';
import { routePaths } from '@/router/routePaths';
import accountService from '@/services/account-service';
import companiesService from '@/services/companies-service';
import usersService from '@/services/users-service';
import httpClient from '@/settings/HttpClient';
import type {
  Event,
  EventPayload,
  IUser,
  ICompany,
  IInviteQuery,
} from '@/ts-definitions';
import type { RootStore } from '../rootStore';

export interface AccountStore {
  isAuthenticated: boolean;
  userToken?: string;
  refreshToken?: string;
  profile?: Partial<IUser>;
  companies?: Partial<ICompany>[];
  authenticatedLink?: RouteLocationRaw;
}

export type SignInMutation = EventPayload<
  'account/signIn' | 'signIn',
  { userToken: string; refreshToken: string }
>;
type SignOutMutation = Event<'account/signOut' | 'signOut'>;
type SaveProfileMutation = EventPayload<
  'account/saveProfile' | 'saveProfile',
  Partial<IUser>
>;
type SaveCompaniesMutation = EventPayload<
  'account/saveCompanies' | 'saveCompanies',
  Partial<ICompany>[]
>;
type SetAuthenticatedLinkMutation = EventPayload<
  'account/setAuthenticatedLink' | 'setAuthenticatedLink',
  RouteLocation
>;

export type AccountMutations =
  | SignInMutation
  | SignOutMutation
  | SaveProfileMutation
  | SaveCompaniesMutation
  | SetAuthenticatedLinkMutation;

type CheckIsUserAuthenticated = Event<'account/checkIsUserAuthenticated'>;
type SignInAction = EventPayload<
  'account/signIn',
  { email: string; password: string }
>;
type SignOutAction = Event<'account/signOut'>;
type ForgotPasswordAction = EventPayload<'account/forgotPassword', string>;
type ResetPasswordAction = EventPayload<
  'account/resetPassword',
  { password: string; token: string }
>;
type SaveCompaniesAction = Event<'account/saveCompanies'>;
type SaveProfileAction = Event<'account/saveProfile'>;
type SetAuthenticatedLinkAction = EventPayload<
  'account/setAuthenticatedLink',
  RouteLocation
>;
type SignUpAction = EventPayload<
  'account/signUp',
  {
    password: string;
    firstname: string;
    lastname: string;
    query: IInviteQuery;
  }
>;
type RefreshTokensAction = EventPayload<
  'account/refreshTokens',
  { userToken: string; refreshToken: string; slug: string }
>;

export type AccountActions =
  | CheckIsUserAuthenticated
  | SignInAction
  | SignOutAction
  | ForgotPasswordAction
  | ResetPasswordAction
  | SaveCompaniesAction
  | SaveProfileAction
  | SetAuthenticatedLinkAction
  | SignUpAction
  | RefreshTokensAction;

export type AccountGetters = keyof AccountGettersValues;

export interface AccountGettersValues {
  'account/getCurrentCompany': Partial<ICompany> | undefined;
}

export const accountStore: Module<AccountStore, RootStore> = {
  namespaced: true,
  state: () => ({
    isAuthenticated: false,
  }),
  getters: {
    getCurrentCompany(state) {
      const route = useRoute();
      const companies = state.companies;
      return companies?.find(
        (company) => company.slug === route.params.companyId
      );
    },
  },
  mutations: {
    signIn(state, { payload: { userToken, refreshToken } }: SignInMutation) {
      state.isAuthenticated = true;
      httpClient.setAuthorizationHeader(userToken);
      httpClient.setRefreshTokenInterceptor();
      localStorage.setItem('userToken', userToken);
      localStorage.setItem('refreshToken', refreshToken);
    },

    signOut(state) {
      state.isAuthenticated = false;
      httpClient.clearConfig();
      useQueryCache().clear();
      localStorage.removeItem('userToken');
      localStorage.removeItem('refreshToken');
    },

    saveProfile(state, { payload }: SaveProfileMutation) {
      state.profile = payload;
    },

    saveCompanies(state, { payload }: SaveCompaniesMutation) {
      state.companies = payload;
    },
    setAuthenticatedLink(state, { payload }: SetAuthenticatedLinkMutation) {
      if (payload) {
        return (state.authenticatedLink = payload);
      }
      delete state.authenticatedLink;
    },
  },
  actions: {
    async checkIsUserAuthenticated(context) {
      const userToken = localStorage.getItem('userToken');
      const refreshToken = localStorage.getItem('refreshToken');
      const { enqueueNotification } = useNotification();
      if (!userToken || !refreshToken) {
        return false;
      } else {
        try {
          const { user_token, refresh_token } =
            await accountService.refreshToken({ refreshToken });
          const event: SignInMutation = {
            type: 'signIn',
            payload: { userToken: user_token, refreshToken: refresh_token },
          };
          context.commit(event);
          await context.dispatch({ type: 'saveProfile' });
          return true;
        } catch (error) {
          enqueueNotification({
            color: 'danger',
            message: error.message,
          });
          return false;
        }
      }
    },

    async signIn(context, { payload }: SignInAction) {
      const { user_token: userToken, refresh_token: refreshToken } =
        await accountService.signIn(payload);

      const signInEvent: SignInMutation = {
        type: 'signIn',
        payload: { userToken, refreshToken },
      };

      context.commit(signInEvent);
      context.dispatch({ type: 'saveProfile' });

      if (context.state.authenticatedLink) {
        await router.push(context.state.authenticatedLink).then(() => {
          context.dispatch({
            type: 'setAuthenticatedLink',
            payload: '',
          });
        });
      } else {
        router.push(routePaths.Company.CompanyHome);
      }
    },

    async refreshTokens(context, { payload }: RefreshTokensAction) {
      const { slug, ...tokens } = payload;
      const signInEvent: SignInMutation = {
        type: 'signIn',
        payload: tokens,
      };
      context.commit(signInEvent);
      context.dispatch({ type: 'saveProfile' });

      await router.push({
        name: 'StudioDesigns',
        params: { companyId: slug },
      });
    },

    async signUp(context, { payload }: SignUpAction) {
      const { query, ...userData } = payload;
      await accountService.signUp({ ...userData, token: query.t });
      await router.push({
        path: routePaths.CompanyInvite.InviteAccept,
        query,
      });
    },

    async signOut(context) {
      const event: SignOutMutation = { type: 'signOut' };
      context.commit(event);
      await router.push(routePaths.SignIn);
    },

    async forgotPassword(context, { payload }: ForgotPasswordAction) {
      await accountService.forgotPassword({ email: payload });
    },

    async resetPassword(context, { payload }: ResetPasswordAction) {
      await accountService.resetPassword(payload);
      await context.dispatch({ type: 'signOut' });
    },

    async saveProfile(context) {
      const { id, uid, firstname, lastname, email } =
        await usersService.getUser();
      const { identify } = useAnalytics();
      identify(id, { firstname, lastname, email });
      const event: SaveProfileMutation = {
        type: 'saveProfile',
        payload: { id, email, uid, firstname, lastname },
      };
      context.commit(event);
    },

    async saveCompanies(context) {
      const { res } = await companiesService.listCompanies();
      const event: SaveCompaniesMutation = {
        type: 'saveCompanies',
        payload: res,
      };
      context.commit(event);
    },

    async setAuthenticatedLink(
      context,
      { payload }: SetAuthenticatedLinkAction
    ) {
      const event: SetAuthenticatedLinkMutation = {
        type: 'setAuthenticatedLink',
        payload,
      };
      context.commit(event);
    },
  },
};

export default accountStore;

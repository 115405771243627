import { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import { rootStore, useCommit } from '@/store/rootStore';

export type ReturnMiddleware = boolean | RouteLocationRaw;
export type InviteMiddleware = (
  to: RouteLocationNormalized,
  requiredQueryKeys: string[]
) => ReturnMiddleware;

export default function useMiddleware() {
  const inviteMiddleware: InviteMiddleware = (to, requiredQueryKeys) => {
    const { isAuthenticated } = rootStore.state.account;
    const commit = useCommit();
    const hasRequiredQueries = requiredQueryKeys.every((key) =>
      Boolean(to.query[key])
    );
    if (hasRequiredQueries) {
      if (isAuthenticated) {
        commit({ type: 'account/signOut' });
      }
      return true;
    }
    return { name: 'InviteError' };
  };

  return {
    inviteMiddleware,
  };
}

import { computed, ComputedRef, InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';

import {
  AccountMutations,
  accountStore,
  AccountStore,
} from './modules/account';
import {
  NotificationMutations,
  notificationStore,
  NotificationStore,
} from './modules/notification';
import {
  SnifferMutations,
  SnifferStore,
  snifferStore,
} from './modules/sniffer';

export interface RootStore {
  account: AccountStore;
  notification: NotificationStore;
  sniffer: SnifferStore;
}

export const rootStore = createStore<RootStore>({
  state: undefined,
  mutations: {},
  actions: {},
  modules: {
    account: accountStore,
    notification: notificationStore,
    sniffer: snifferStore,
  },
});

export const key: InjectionKey<Store<RootStore>> = Symbol();

export function useStore() {
  return baseUseStore(key);
}

export type StoreMutations =
  | AccountMutations
  | NotificationMutations
  | SnifferMutations;

export function useCommit() {
  return (action: StoreMutations) => rootStore.commit(action);
}

export type StoreActions = any;

export function useDispatch() {
  return async (action: StoreActions) => rootStore.dispatch(action);
}

function useBaseSelector<TState = Record<string, unknown>, TSelected = unknown>(
  selector: (state: TState) => TSelected
): ComputedRef<TSelected>;
function useBaseSelector(selector: (state: any) => any): any {
  return computed(() => selector(rootStore.state));
}

export type TypedUseSelector<TState> = <TSelected>(
  selector: (state: TState) => TSelected
) => ComputedRef<TSelected>;

/**
 * A hook to access the vuex store's state. This hook takes a selector function
 * as an argument. The selector is called with the store state.
 *
 * @param selector the selector function
 *
 * @returns the selected state
 *
 * @example
 *
 * const counter = useSelector((state) => state.counter)
 */
export const useSelector: TypedUseSelector<RootStore> = useBaseSelector;
